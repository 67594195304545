import React from "react";
import SearchIcon from "../../utils/icons/search-icon.svg";
import GlobalPagination from "../primary/global-elements/global-pagination-btns";
import { useLocation, useNavigate } from "react-router-dom";
import { IMigrationValue, useUserActions } from "../../context-api/actions";
import { useAppContext } from "../../context-api";
import { ActionIcon } from "../../utils/icons";
import Modal from "../primary/modals";
import MigrateDetails from "../migrate-requests/migrate-details";
import GlobalTableSkeleton from "../primary/global-elements/global-tables/global-table-skeleton";
import CustomDropdown from "./custom-dropdown";
import { dateWithSlashes } from "../../utils/date-time-formatter";
import Icon from "../../utils/icons/Icon";
import { debounce } from "../../utils";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const MigrationRequests: React.FC = () => {
  const navigate = useNavigate();
  const { pathname = "" } = useLocation();
  const query = useQuery();
  const modal: string | undefined | null = query.get("modal");

  const isOpen = modal === "true";

  const { getAllMigration } = useUserActions();
  const {
    state: { migrationRequests, fetchLoading, },
  } = useAppContext();

  const { requests = [], totalPages } = migrationRequests;

  const [page, setPage] = React.useState<number>(1);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [status, setStatus] = React.useState<string>('');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleModalOpen = (params: { id: string }) => {
    const { id } = params;

    if (!id) {
      return;
    }

    navigate(`${pathname}?id=${id}&modal=true`);
  };

  const handleModalClose = () => {
    navigate(`${pathname}`);
  };

  const handleReset = () => {
    if (searchTerm === "" && status === "") {
      return;
    }
    setStatus("");
    setSearchTerm("");
    setPage(1);
    getAllMigration({ page: 1, query: "" });
  };

  const handleStatusChange = (status: string) => {
    setStatus(status);
  }

  const debouncedSearch = React.useCallback(
    debounce((searchTerm: string) => {
      getAllMigration({ page, query: searchTerm, status });
    }, 500),
    [page, status]
  );

  React.useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm, debouncedSearch, status]);

  React.useEffect(() => {
    getAllMigration({ page });
  }, [page]);

  return (
    <div className='flex flex-col space-y-4 pt-6'>
      <div className='flex space-x-3'>
        <div className='flex items-center gap-2 border border-app-gray-300 rounded-lg max-w-sm p-0.5'>
          <label htmlFor='search' className='pl-1'>
            <SearchIcon />
          </label>
          <input
            type='text'
            id='search'
            placeholder='Search...'
            value={searchTerm}
            onChange={handleSearch}
            className='py-1.5 px-2 border-none w-full outline-none focus:ring-0 bg-transparent'
          />
        </div>

        <div className="flex items-center gap-2 border border-app-gray-300 rounded-lg max-w-sm p-0.5">
          <select
            id='status'
            className='py-1.5 px-2 border-none w-full outline-none focus:ring-0 bg-transparent text-sm text-app-gray-400'
            onChange={(e) => handleStatusChange(e.target.value)}
            value={status}
          >
            <option value=''>All</option>
            <option value='pending'>Pending</option>
            <option value='approved'>Approved</option>
            <option value='rejected'>Rejected</option>
          </select>
        </div>
        <button className='text-sm font-medium text-app-gray-400 py-1.5' onClick={handleReset}>
          Reset
        </button>
      </div>
      <div className='overflow-x-auto small-scroll'>
        <table className='table-auto text-nowrap min-w-full divide-y divide-app-borderGray'>
          <thead className="">
            <tr className="bg-app-tableHeader" style={{ borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem' }}>
              <th className='px-6 py-3 text-center text-xs font-medium text-app-dark capitalize tracking-wider'>
                BID
              </th>
              <th className='px-6 py-3 text-center text-xs font-medium text-app-dark capitalize tracking-wider'>
                Name
              </th>
              <th className='px-6 py-3 text-center text-xs font-medium text-app-dark capitalize tracking-wider'>
                Username
              </th>
              <th className='px-6 py-3 text-center text-xs font-medium text-app-dark capitalize tracking-wider'>
                Email
              </th>
              <th
                className='px-6 py-3 text-center text-xs font-medium text-app-dark capitalize tracking-wider'
              >
                Created At
              </th>
              <th className='px-6 py-3 text-center text-xs font-medium text-app-dark capitalize tracking-wider'>
                Status
              </th>
              <th className='px-6 py-3 text-center font-medium text-app-dark capitalize tracking-wider'>
                <div className="text-xs flex items-center justify-center gap-2">
                  <ActionIcon />
                  Action
                </div>
              </th>
            </tr>
          </thead>
          {fetchLoading ? (
            <GlobalTableSkeleton count={7} />
          ) : (
            <tbody className="">
              {requests?.map((item: IMigrationValue, _idx) => (
                <tr key={item._id} className='border-b border-app-gray-200 text-center border-x'>
                  <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                    {item.user.blockchainId}
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm text-app-dark'>
                    {item.user?.firstName} {item.user?.lastName}
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm text-app-dark'>
                    {item.user?.userName}
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm text-app-dark'>
                    {item.user.email}
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm text-app-dark'>
                    {dateWithSlashes(item.createdAt)}
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm'>
                    <CustomDropdown id={item?._id || ''} page={page} selectedvalue={item?.status as "pending" | "approved" | "rejected"} />
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-lg font-medium'>
                    <button
                      className='text-app-gray-400 bg-app-tableHeader rounded-lg hover:opacity-80 p-1 app-transitioin-300-all  border'
                      onClick={() => handleModalOpen({ id: item._id || "" })}
                    >
                      <Icon icon="TableActionIcon" className="text-2xl" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {requests.length > 1 && totalPages > 0 ? <tfoot>
            <tr className="">
              <td colSpan={7} className="bg-app-tableHeader" style={{ borderBottomLeftRadius: '0.5rem', borderBottomRightRadius: '0.5rem' }}>
                <div className='flex items-center justify-end py-2 px-4'>
                  <GlobalPagination
                    totalPages={totalPages}
                    currentPage={page}
                    onPageChange={(page) => {
                      if (fetchLoading) {
                        return;
                      }
                      setPage(page);
                    }}
                  />
                </div>
              </td>
            </tr>
          </tfoot> : null}
        </table>

        {!fetchLoading && requests.length < 1 && (
          <div className='flex justify-center items-center h-32 text-app-gray-400'>
            No Data Found
          </div>
        )}
      </div>



      <Modal isOpen={isOpen} onClose={handleModalClose}>
        <MigrateDetails page={page} />
      </Modal>
    </div>
  );
};

export default MigrationRequests;
