import React from "react";
import { Outlet } from "react-router-dom";
import ContainerComponent from "../primary/container";
import AuthBanner from "./auth-banner";
import AppLogoComponent from "../primary/app-header/app-logo";

interface AuthLayoutProps {}

const AuthLayout: React.FC<AuthLayoutProps> = () => {
  return (
    <><div className="bg-app-dark">
        <ContainerComponent className='flex items-center justify-center'>
          <AppLogoComponent />
        </ContainerComponent>
      </div>
      <div className='max-w-7xl mx-auto'>
      <ContainerComponent>
        <div className='grid md:grid-cols-2 py-10 content-center items-center'>
          <div className='grid place-content-center order-2'>
            <AuthBanner />
          </div>
          <div className='max-w-[576px]'>
            <Outlet />
          </div>
        </div>
      </ContainerComponent>
    </div>
    </>
    
  );
};

export default AuthLayout;
