import React from "react";
import ProfilePopover from "./profile-popover";
import { Link } from "react-router-dom";
import Icon from "../../../utils/icons/Icon";
import { useUserActions } from "../../../context-api/actions";

const UserDropdown: React.FC = () => {
  const dropdownRef = React.useRef<HTMLDivElement>(null);
  const { userInfoFromState, userLogout } = useUserActions();
  const user = userInfoFromState();

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // const profileImageUrl: string = user?.profilePhoto?.url || "https://admin.homnifi.codeinprogress.net/img/Profile/profile.jpg";

  return (
    <div className='hidden md:block rounded-full pr-[17px]'>
      <div className='relative'>
        {user === null ? (
          <div className='text-app-light block text-nowrap border rounded-small p-1.5'>
            <Link to={`/auth/signin`}>Sign in</Link>
          </div>
        ) : (
          <button
            onClick={toggleDropdown}
            className='flex items-center space-x-2 text-sm'
          >
            {/* <img
              src={profileImageUrl}
              alt='Profile'
              className='w-8 h-8 rounded-full object-cover !m-0 !p-0 object-top'
            /> */}

            <span className='text-app-light whitespace-nowrap'>
              {user?.firstName} {user?.lastName}
            </span>
            <Icon
              icon='DownArrow'
              className={`w-3 h-3 app-transition-all-300 ${isDropdownOpen ? "rotate-180" : "rotate-0"
                }`}
            />
          </button>
        )}

        <div
          className={`z-50 absolute top-[35px] right-0 mt-2 w-48 bg-white text-black rounded shadow-lg app-transition-all-300 ${isDropdownOpen ? "opacity-100" : "opacity-0 hidden"
            }`}
          ref={dropdownRef}
        >
          <ProfilePopover
            user={user}
            // profileImageUrl={profileImageUrl}
            setDropdownOpen={setIsDropdownOpen}
            handleLogout={userLogout}
          />
        </div>
      </div>
    </div>
  );
};

export default UserDropdown;
