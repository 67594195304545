//REACT
import React, { useState } from "react";
//REACT-ROUTER-DOM
import { useLocation, useNavigate } from "react-router-dom";
//COMPONENTS
import IconLabel from "../primary/icon-label";
import LogoutIcon from "../../utils/icons/logout.svg";
import UserIcon from "../../utils/icons/user.svg";
import { useUserActions } from "../../context-api/actions";

const BottomBar = () => {
  const { userLogout } = useUserActions();
  const [isSelected, setIsSelected] = useState("");

  const selectedClassName = "bg-primary/10 text-primary";

  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (location.pathname === "/profile") {
      setIsSelected("profile");
    } else {
      setIsSelected("");
    }
  }, [location]);

  return (
    <div className='z-30 md:hidden sticky bottom-0 left-0 border-t border-borderBlue w-full py-2 px-10 flex justify-between items-center bg-white'>
      <button
        className={`py-2 px-4 rounded-md transition-all ease-in-out delay-75 ${
          isSelected === "profile" ? selectedClassName : ""
        }`}
        onClick={() => {
          setIsSelected("profile");
          navigate("/profile");
        }}
      >
        <IconLabel
          className='flex-col items-center justify-center w-max'
          Icon={<UserIcon />}
          label={"Profile"}
          labelClass={"text-xs"}
        />
      </button>
      <button
        className={`text-black hover:text-primary hover:bg-selectedLightBlue py-2 px-4 rounded-md transition-all ease-in-out delay-75 ${
          isSelected === "notifications" ? selectedClassName : ""
        }`}
        onClick={() => {
          userLogout();
        }}
      >
        <IconLabel
          className='flex-col items-center justify-center w-max'
          Icon={<LogoutIcon />}
          label={"Logout"}
          labelClass={"text-xs"}
        />
      </button>
    </div>
  );
};

export default BottomBar;
