import { useAppContext } from "../../context-api";
import Spinner from "../primary/spinner";
import PieChart from "./pie-chart";

function ChartData() {
    const { state } = useAppContext();
    const chartData = state?.dashboardData?.migrationRequests;
    const pending = Math.ceil((chartData?.pending / chartData?.total) * 100);
    const approved = Math.ceil((chartData?.approved / chartData?.total) * 100);
    const rejected = Math.ceil((chartData?.rejected / chartData?.total) * 100);
    return (
            <div className="bg-white border border-[#D9E4F1] px-7 py-5 rounded-xl space-y-5">
                {!chartData ?
                    (<div className='w-full min-h-full flex justify-center items-center'>
                        <Spinner />
                    </div>)
                    : (
                        <>
                            <PieChart pending={pending} approved={approved} rejected={rejected} />
                            <div className='flex justify-between mt-5 border-t border-[#E6E6E6] pt-5 gap-2'>
                                <div className='flex items-center'>
                                    <div className='w-4 h-4 bg-[#9B70FF] rounded-sm mr-2'></div>
                                    <p className='text-xs xl:text-sm'><span>{pending ? pending : 0}</span>% Pending</p>
                                </div>
                                <div className='flex items-center'>
                                    <div className='w-4 h-4 bg-[#11B5A7] rounded-sm mr-2'></div>
                                    <p className='text-xs xl:text-sm'><span>{approved ? approved : 0}</span>% Approved</p>
                                </div>
                                <div className='flex items-center'>
                                    <div className='w-4 h-4 bg-[#E54252] rounded-sm mr-2'></div>
                                    <p className='text-xs xl:text-sm'><span>{rejected ? rejected : 0}</span>% Rejected</p>
                                </div>

                            </div>
                        </>
                    )
                }

            </div>
    )
}

export default ChartData
