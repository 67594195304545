export function formatLargeNumber(value: number, decimals: number = 2): string {
    if (value < 1_000_000) return value.toFixed(decimals);
  
    const units = [
      { value: 1_000_000_000_000, symbol: "T" }, // Trillion
      { value: 1_000_000_000, symbol: "B" },     // Billion
      { value: 1_000_000, symbol: "M" },         // Million
    ];
  
    for (const unit of units) {
      if (value >= unit.value) {
        return (value / unit.value).toFixed(decimals) + unit.symbol;
      }
    }
  
    return value.toString(); // Fallback for very small numbers or invalid input
  }