import { createContext, useContext, useState, useCallback, ReactNode } from 'react';
import toast, { Toaster } from 'react-hot-toast';

// ...existing code...

interface ToastContextType {
    addToast: (message: string, options?: object) => void;
    addAppCustomToast: (message: string, options?: object) => void;
    removeToast: (id: string) => void;
}

interface ToastOptions {
    [key: string]: any;
}

interface Toast {
    id: number;
    message: string;
    options?: ToastOptions;
}

const ToastContext = createContext<ToastContextType | null>(null);

export const ToastProvider = ({ children }: { children: ReactNode; }) => {
    const [toasts, setToasts] = useState<Toast[]>([]);


    const addToast = useCallback((message: string, options?: ToastOptions) => {
        const id = toast(message, options);
        setToasts((prevToasts: any) => [...prevToasts, { id, message, options }]);
    }, []);

    const addAppCustomToast = useCallback((message: string, options?: ToastOptions) => {
        const id = toast.custom((t) => (
            <button
                onClick={() => toast.dismiss(t.id)}
                className="p-4 max-w-sm w-full"
            >
                <div
                    className={`${t.visible ? 'animate-enter' : 'animate-leave'
                        } max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-app-primary ring-opacity-5`}
                >
                    <div className="flex-1 w-0 p-4">
                        <div className="flex">
                            <div className="ml-3 flex-1">
                                <p className="text-sm font-medium text-app-dark">
                                    {message}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </button>
        ), {
            duration: 5000,
            position: 'top-right',
        });
        setToasts((prevToasts: any) => [...prevToasts, { id, message, options }]);
    }, []);

    const removeToast = useCallback((id: any) => {
        toast.dismiss(id);
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    }, []);

    return (
        <ToastContext.Provider value={{ addToast, addAppCustomToast, removeToast }}>
            {children}
            <Toaster
                position="top-right"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },

                    // Default options for specific types
                    success: {
                        duration: 3000,
                    },
                }}
            />
        </ToastContext.Provider>
    );
};

export const useAppToast = () => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
};

// ...existing code...
