import React, { useEffect } from "react";
import { useAppContext } from "../context-api";
import Icon, { IconType } from "../utils/icons/Icon";
import NivoBar from "../components/primary/charts";
import { useUserActions } from "../context-api/actions";
import BalanceCard from "../components/dashboard/balance-card";
import MigrationRequestsCard from "../components/dashboard/migration-requests-card";
import { PendingReq, Spinner } from "../utils/icons";
import ChartData from "../components/dashboard/chart-data";

const Dashboard: React.FC = () => {
  const { state } = useAppContext();
  const { getDashboardDetails } = useUserActions();
  const balances = state?.dashboardData?.balances;
  const migrationReqs = state?.dashboardData?.migrationRequests;
  useEffect(() => {
    getDashboardDetails()
    console.log("state", state?.dashboardData);
  }, []);

  const DashboardCard = ({
    icon,
    name,
    value,
    subvalue,
    content
  }: {
    icon?: IconType;
    name: string;
    value: number | string;
    subvalue?: string;
    content?: React.ReactNode;
  }) => (
    <div className='after:content-[""]  after:absolute after:right-0 after:top-[50%] after:translate-y-[-50%] after:w-[1px] after:bg-[#D9E4F1] last:after:w-0 after:h-[50%] relative p-5'>
      <div className='flex flex-col justify-center h-full'>

        <div className="flex items-end space-x-3">
          <Icon icon={icon as IconType} className="text-4xl" />
          <div className="">
            <h4 className='font-rubik text-[12px] md:text-[15px] font-normal capitalize'>
              {name}
            </h4>
            <div className="flex gap-2 flex-wrap items-end">
              <h3 className="text-4xl font-medium">{value}</h3>
              <p className="text-2xl font-medium">{subvalue}</p>
            </div>
          </div>
        </div>

        <div className="flex items-end justify-between pt-5 pb-2">
          <div className="flex flex-col space-y-3">
            <div className="flex items-center space-x-2">
              <Icon icon='CalenderIcon' className="text-base" /> <p className="text-xs">From Jan, 2024</p>
            </div>

            {content}
          </div>
          <button className='mt-10 flex items-center gap-x-1.5 text-sm'>
            <h6>View All</h6>
            <Icon icon='ArrowRight' className='text-[12px]' />
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className='p-4 grid space-y-4'>
      <div className=''>
        <h1 className="text-2xl mb-10">Dashboard</h1>
        <div className="bg-white border-[#D9E4F1] border rounded-xl px-14 py-6 mb-20">
          {!balances ? <Spinner /> : (
            <div className="grid grid-cols-1 md:grid-cols-3 md:divide-x divide-[#D9E4F1] gap-10">
              <BalanceCard iconName='Total' title='Total Balance' amount={balances?.capital} />
              <BalanceCard iconName='Residue' title='Residue  Balance' amount={balances?.balance} />
              <BalanceCard iconName='Migrated' title='Migrated Balance' amount={balances?.migrated} />
            </div>
          )}

        </div>

        <h1 className="text-2xl mb-10">Migration Requests</h1>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
          <MigrationRequestsCard iconName={"PendingReq"} title='Pending Requests' amount={migrationReqs?.pending} />
          <MigrationRequestsCard iconName={"ApproveReq"} title='Approved Requests' amount={migrationReqs?.pending} />
          <MigrationRequestsCard iconName={"RejectedReq"} title='Rejected Requests' amount={migrationReqs?.pending} />
          <ChartData />
        </div>
      </div>
    </div >
  );
};

export default Dashboard;
