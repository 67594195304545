import currentStorage from "../utils/currentStorage";
import { LOGGEDINUSER } from "../utils/constants";
import React from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const AuthProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const storage = currentStorage();
  const isAuthenticated = storage.getItem(LOGGEDINUSER) === 'true';

  if (isAuthenticated) {
    return <Navigate to='/' />;
  }

  return <>{children}</>;
};

export default AuthProtectedRoute;
