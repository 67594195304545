import { AuthBanner as authimage } from "../../images";

function AuthBanner() {
  return (
    <div className=''>
      <img
        src={authimage}
        className='w-full h-auto'
        alt='Auth Banner'
      />
    </div>
  );
}
export default AuthBanner;
