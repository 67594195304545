/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ProfileHeading from "../primary/headings/profile-headings";
import GlobalInput from "../primary/global-elements/global-input";
import GlobalButton from "../primary/global-elements/global-button";
import { useUserActions } from "../../context-api/actions";

export default function Profile(): JSX.Element {
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const formikRef = React.useRef<any>(null);
  const { userInfoFromState, updateAdminProfile } = useUserActions();
  const user = userInfoFromState();

  const initialValues = {
    email: user?.email ? user?.email : "",
    username: user?.adminName ? user?.adminName : "",
    firstName: user?.firstName ? user?.firstName : "",
    lastName: user?.lastName ? user?.lastName : "",
    phoneNumber: user?.phone ? user?.phone : "",
  };

  React.useEffect(() => { }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    username: Yup.string().required("Username is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    phoneNumber: Yup.string(),
  });

  const onSubmit = async (values: any) => {
    setIsSubmitting(true);
    try {
      const { firstName, lastName, phoneNumber } = values;
      // Make an API call to update the user profile
      await updateAdminProfile({ firstName, lastName, phone: phoneNumber });
      setIsSubmitting(false);
    } catch (error) {
      console.log(error);

    } finally {
      setIsSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    initialTouched: {
      email: true,
      username: true,
      firstName: true,
      lastName: true,
      phoneNumber: true,
    },
    innerRef: formikRef,
  });

  const {
    handleChange,
    handleSubmit: handleFormikSubmit,
    values,
    errors,
    touched,
    setValues,
  } = formik;

  React.useEffect(() => {
    if (user) {
      setValues({
        email: user?.email ? user?.email : "",
        username: user?.adminName ? user?.adminName : "",
        firstName: user?.firstName ? user?.firstName : "",
        lastName: user?.lastName ? user?.lastName : "",
        phoneNumber: user?.phone ? user?.phone : "",
      });
    }
  }, [user]);
  return (
    <div className='w-full px-2 py-2 sm:py-8 mb-20 lg:px-8 max-w-[1700px] mx-auto '>
      <div className='flex items-center flex-col md:flex-row  text-primary mb-4'>
        <ProfileHeading className='flex-1 !text-app-dark'>
          Profile
        </ProfileHeading>
      </div>
      <form onSubmit={handleFormikSubmit}>
        <div className='w-full sm:py-8 mb-20 lg:p-8 p-4 max-w-[1200px] bg-white mx-auto mt-12 rounded-xl border border-borderBlue shadow-sm'>
          <div className='grid grid-cols-1 gap-x-6 lg:grid-cols-2 mx-2 md:mx-12 items-start'>
            {Object.keys(initialValues).map((key) => (
              <div key={key}>
                <GlobalInput
                  required
                  label={capitalizeFirstLetter(key)}
                  type={key}
                  name={key}
                  disabled={key === "email" || key === "username"}
                  error={
                    errors[key as keyof typeof initialValues] &&
                      touched[key as keyof typeof initialValues]
                      ? "error"
                      : ""
                  }
                  value={values[key as keyof typeof initialValues]}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (key === "email") {
                      // Remove whitespace from the email input
                      const newValue = e.target.value.replace(/\s+/g, "");
                      formik.setFieldValue("email", newValue);
                    } else {
                      handleChange(e);
                    }
                  }}
                />
                {touched[key as keyof typeof initialValues] &&
                  errors[key as keyof typeof initialValues] && (
                    <div className='text-red-500 text-xs'>
                      {errors[key as keyof typeof initialValues]}
                    </div>
                  )}
              </div>
            ))}
          </div>

          <div className='flex flex-col md:flex-row items-center justify-end md:px-12 mt-9 w-full'>
            <GlobalButton
              loading={isSubmitting}
              classNames='bg-primary'
              text={"Update"}
              type='submit'
            />
          </div>
        </div>
      </form>
    </div>
  );
}

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
