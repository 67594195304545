import React from "react";
import UsersTable from "../components/users";

const Users: React.FC = () => {
  return (
    <div className="pt-6">
      <UsersTable />
    </div>
  );
};

export default Users;
