import axios from 'axios';
import currentStorage from '../../utils/currentStorage';
import React from 'react';

const PdfViewer = ({ pdfUrl, name = "text-file" }: any) => {
    const [fetchLoading, setFetchLoading] = React.useState(false);
    const getToken = () => currentStorage().getItem("token")

    const fetchPdf = async () => {
        setFetchLoading(true);
        try {
            const response = await axios.get(pdfUrl, {
                headers: {
                    Authorization: `Bearer ${getToken()}`, // Replace token with your actual value
                },
                responseType: 'blob', // Important for handling binary data
            });

            const blob = response.data;

            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${name}-agreement.pdf`); // Specify the name of the PDF file
            document.body.appendChild(link);
            link.click();
            link?.parentNode?.removeChild(link);
        } catch (error) {
            console.error('Error fetching PDF:', error);
        }
        finally {
            setFetchLoading(false);
        }
    };

    return {
        fetchPdf,
        fetchLoading
    };
};

export default PdfViewer;