import { cn } from "../cn";
import * as icons from "./index";

export type IconType = keyof typeof icons;

type Props = {
  icon: IconType;
  className?: string;
  fill?: string;
};

export default function Icon(props: Props) {
  const { className, icon } = props;

  const IconComponent = icons[icon] as unknown as React.ComponentType<{ className?: string }>;

  return (
    <IconComponent
      className={cn(
        "flex-shrink-0",
        className
      )}
      // fill={fill}
    />
  );
}

Icon.displayName = "Icon";
