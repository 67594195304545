import React from "react";
import { cn } from "../../../utils/cn";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string; //? For Modal Body styling
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, className }) => {
  if (!isOpen) return null;

  return (
    <div className='fixed inset-0 z-[1002] flex items-center justify-center app-transition-all-300'>
      <div
        className='fixed inset-0 bg-black opacity-50'
        onClick={onClose}
      ></div>
      <div className={cn(`bg-white rounded-lg shadow-lg p-6 z-10 max-w-screen-md w-full md:w-[calc(100%-20%)] max-h-[calc(100vh-20%)] overflow-y-auto`, className)}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
