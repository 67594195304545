import React from "react";
import GlobalPagination from "../primary/global-elements/global-pagination-btns";
import SearchIcon from "../../utils/icons/search-icon.svg";
import { IApplicationUser, useUserActions } from "../../context-api/actions";
import { useAppContext } from "../../context-api";
import GlobalTableSkeleton from "../primary/global-elements/global-tables/global-table-skeleton";
import { dateWithSlashes } from "../../utils/date-time-formatter";
import Icon from "../../utils/icons/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "../primary/modals";
import UserDetailsModal from "./user-details-modal";
import { debounce } from "../../utils";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const UsersTable: React.FC = () => {
  const { getAllUsers } = useUserActions();
  const {
    state: { applicationUsers, fetchLoading },
  } = useAppContext();

  const navigate = useNavigate();
  const { pathname = "" } = useLocation();
  const query = useQuery();
  const modal: string | undefined | null = query.get("modal");

  const isOpen = modal === "true";

  const { users = [], totalPages } = applicationUsers;
  const [searchTerm, setSearchTerm] = React.useState("");
  const [page, setPage] = React.useState<number>(1);


  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };


  const handleReset = () => {
    if (searchTerm === "") {
      return;
    }
    setSearchTerm("");
    setPage(1);
    getAllUsers({ page: 1, query: "" });
  };

  const debouncedSearch = React.useCallback(
    debounce((term: string) => {
      getAllUsers({ page, query: term });
    }, 500),
    [page]
  );

  const handleModalOpen = (params: { id: string }) => {
    const { id } = params;

    if (!id) {
      return;
    }

    navigate(`${pathname}?id=${id}&modal=true`);
  };

  const handleModalClose = () => {
    navigate(`${pathname}`);
  };



  React.useEffect(() => {
    debouncedSearch(searchTerm);
  }, [searchTerm, debouncedSearch]);

  React.useEffect(() => {
    getAllUsers({ page });
  }, [page]);

  return (
    <div className='flex flex-col space-y-4'>
      <div className='flex space-x-3'>
        <div className='flex items-center gap-2 border border-app-gray-300 rounded-lg max-w-sm p-0.5'>
          <label htmlFor='search' className='pl-1'>
            <SearchIcon />
          </label>
          <input
            type='text'
            id='search'
            placeholder='Search...'
            value={searchTerm}
            onChange={handleSearch}
            className='py-1.5 px-2 border-none w-full outline-none focus:ring-0 bg-transparent'
          />
        </div>
        <button className='text-sm font-medium text-app-gray-400 py-1.5' onClick={handleReset}>
          Reset
        </button>

      </div>
      <div className='overflow-x-auto small-scroll'>
        <table className='table-auto text-nowrap min-w-full divide-y divide-app-gray-200'>
          <thead className="sticky top-0">
            <tr className="bg-app-tableHeader" style={{ borderTopLeftRadius: '0.5rem', borderTopRightRadius: '0.5rem' }}>
              <th className='px-6 py-3 text-left text-xs font-medium text-app-dark capitalize tracking-wider'>
                BID
              </th>
              <th className='px-6 py-3 text-left text-xs font-medium text-app-dark capitalize tracking-wider'>
                Name
              </th>
              <th className='px-6 py-3 text-left text-xs font-medium text-app-dark capitalize tracking-wider'>
                Username
              </th>
              <th className='px-6 py-3 text-left text-xs font-medium text-app-dark capitalize tracking-wider'>
                Email
              </th>
              <th className='px-6 py-3 text-left text-xs font-medium text-app-dark capitalize tracking-wider'>
                Current Capital
              </th>
              <th className='px-6 py-3 text-left text-xs font-medium text-app-dark capitalize tracking-wider'>
                Residue Amount
              </th>
              <th
                className='px-6 py-3 text-left text-xs font-medium text-app-dark capitalize tracking-wider'
              >
                Created At {/*sortOrder === "asc" ? "↑" : "↓"*/}
              </th>
              <th
                className='px-6 py-3 text-left text-xs font-medium text-app-dark capitalize tracking-wider'
              >
                Action {/*sortOrder === "asc" ? "↑" : "↓"*/}
              </th>
            </tr>
          </thead>
          {fetchLoading ? (
            <GlobalTableSkeleton count={8} />
          ) : (
            <tbody className="">
              {users?.map((item: IApplicationUser, _idx: number) => (
                <tr key={_idx} className='border-b border-app-gray-200 border-x'>
                  <td className='px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900'>
                    {item.blockchainId}
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm text-app-dark'>
                    {item.firstName} {item.lastName}
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm text-app-dark'>
                    @{item.userName}
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm text-app-dark'>
                    {item.email}
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm text-app-dark'>
                    $ {item.capital}
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm text-app-dark'>
                    $ {item.balance}
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-sm text-app-dark'>
                    {dateWithSlashes(item?.createdAt)}
                  </td>
                  <td className='px-6 py-4 whitespace-nowrap text-lg font-medium'>
                    <button
                      className='text-app-gray-400 bg-app-tableHeader rounded-lg hover:opacity-80 p-1 app-transitioin-300-all  border'
                      onClick={() => handleModalOpen({ id: item._id || "" })}
                    >
                      <Icon icon="TableActionIcon" className="text-2xl" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
          {users.length > 1 && totalPages > 0 ? <tfoot>
            <tr className="">
              <td colSpan={8} className="bg-app-tableHeader" style={{ borderBottomLeftRadius: '0.5rem', borderBottomRightRadius: '0.5rem' }}>
                <div className='flex items-center justify-end py-2 px-4'>
                  <GlobalPagination
                    totalPages={totalPages}
                    currentPage={page}
                    onPageChange={(page) => {
                      if (fetchLoading) {
                        return;
                      }
                      setPage(page);
                    }}
                  />
                </div>
              </td>
            </tr>
          </tfoot> : null}
        </table>

        {!fetchLoading && users.length < 1 && (
          <div className='flex justify-center items-center h-32 text-app-gray-400'>
            No Data Found
          </div>
        )}
      </div>


      <Modal isOpen={isOpen} onClose={handleModalClose} className="max-w-screen-lg p-3">
        <UserDetailsModal page={page} />
      </Modal>
    </div>
  );
};

export default UsersTable;
