//REACT
import { memo } from "react";
//REACT-ROUTER-DOM
import { Link, useNavigate } from "react-router-dom";
import { User } from "../../../context-api/actions";

type Props = {
  user: User | null;
  profileImageUrl?: string;
  handleLogout?: () => Promise<void>;
  setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ProfilePopOver = ({
  setDropdownOpen,
  handleLogout,
  user,
  profileImageUrl
}: Props) => {
  const navigate = useNavigate();

  const userProfile = () => navigate("/profile");
  


  return (
    <>
      <div className='flex flex-col absolute top-2 right-0 w-80 bg-white border border-app-gray-300 shadow-lg h-auto rounded-lg'>
        <div className='w-full flex justify-start gap-2 items-center p-4'>
          {/* <div>
            <div className='w-16 h-16 overflow-hidden'>
              <img
                src={profileImageUrl} //? user?.profilePicture ? user?.profilePicture :
                alt='Profile'
                className='w-full h-full rounded-full object-cover'
              />
            </div>
          </div> */}
          <div className='flex flex-col gap-2 w-full'>
            <div className='flex flex-col gap-0'>
              <Link
                to='/profile'
                className='text-lg font-medium leading-0 flex items-center gap-2'
              // onClick={() => setDropdownOpen(false)}
              >
                @{user?.firstName} {user?.lastName}
              </Link>
              <Link
                to='/profile'
                className='text-xs text-app-gray-400'
                onClick={() => {
                  setDropdownOpen && setDropdownOpen(false);
                }}
              >
                {user?.email}
              </Link>
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-0'>
          <button
            onClick={() => {
              setDropdownOpen(false);
              userProfile();
            }}
            className='font-medium flex items-center gap-1 text-base hover:text-app-primary transition-all py-3 px-4'
          >
            Profile
          </button>
          <hr />
          <button className='font-medium flex items-center gap-1 text-base text-app-gray-400 hover:text-app-primary transition-all py-3 px-4' onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>
    </>
  );
};

export default memo(ProfilePopOver);
