export const LOGGEDINUSER = "loggedinUser";

export const MIGRATION_STATUSES = {
  pending: "pending",
  approved: "approved",
  rejected: "rejected",
};

const environment = process.env;

export const base_url = environment.REACT_APP_ADMIN_BASE_URL;
