import { Action, ACTIONS, State } from "./actions";

const {
  SET_FETCH_LOADING,

  SET_ADMIN,
  SET_SIGNIN_LOADING,
  SET_ALL_USERS,
  SET_LOGOUT_LOADING,

  SET_USER_DETAILS,
  SET_USER_DETAILS_LOADING,

  SET_ALL_MIGRATIONS,
  SET_MIGRATION_DETAILS,
  SET_MIGRATION_DETAILS_LOADING,
  GET_DASHBOARD_DATA
} = ACTIONS;

// Create a reducer
export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    // ? APPLICATION ACTIONS
    case SET_FETCH_LOADING:
      return { ...state, fetchLoading: action.payload };

    //?AUTH PAGE ACTIONS
    case SET_ADMIN:
      return { ...state, user: action.payload };
    case SET_SIGNIN_LOADING:
      return { ...state, loginLoading: action.payload };
    case SET_LOGOUT_LOADING:
      return { ...state, logoutLoading: action.payload };

    //? USERS PAGE ACTIONS
    case SET_ALL_USERS:
      return { ...state, applicationUsers: action.payload };
    case SET_USER_DETAILS:
      return { ...state, userDetails: action.payload };
    case SET_USER_DETAILS_LOADING:
      return { ...state, userDetailsLoading: action.payload };

    //? MIGRATIONS PAGE ACTIONS
    case SET_ALL_MIGRATIONS:
      return { ...state, migrationRequests: action.payload };
    case SET_MIGRATION_DETAILS:
      return { ...state, migrationDetails: action.payload };
    case SET_MIGRATION_DETAILS_LOADING:
      return { ...state, migrationDetailsLoading: action.payload };
    case GET_DASHBOARD_DATA:
      return { ...state, dashboardData: action.payload };
    default:
      return state;
  }
};
