import React from "react";
import DangerWarning from "../../../utils/icons/danger-warning.svg";
import ShowPassword from "../../../utils/icons/show-password.svg";
import HidePassword from "../../../utils/icons/hide-password.svg";

type TGlobalInput = {
  name?: string;
  label?: string;
  placeholder?: string;
  type?: string;
  accept?: string;
  required?: boolean;
  isError?: boolean | any;
  error?: string;
  value?: string | any;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.FocusEvent<HTMLElement, Element>
  ) => void;
  className?: string;
  disabled?: boolean;
  labelStyle?: string;
  containerStyles?: string;
  isApiError?: boolean;
  phoneInput?: boolean;
  toolTip?: string;
  classInput?: string;
  isLabeled?: boolean;
  keys?: boolean;
  id?: string;
};

const GlobalInput = ({
  label,
  placeholder,
  type,
  required,
  name,
  isError,
  error,
  value,
  accept,
  isLabeled = true,
  onChange,
  className,
  disabled,
  labelStyle,
  onBlur,
  containerStyles,
  isApiError = false,
  // phoneInput = false,
  toolTip,
  id,
}: TGlobalInput) => {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <div className={`flex flex-col my-2 gap-2 ${containerStyles}`}>
      <div
        className={`text-sm ${
          disabled ? "font-normal" : "font-medium"
        } ${labelStyle}`}
      >
        {label && isLabeled && (
          <div
            className={`text-sm flex font-medium text-defaultBlack ${labelStyle}`}
          >
            {label}
            {required && <span className='text-app-danger'>*</span>}
            {/* {toolTip && (
              <Tooltip title={toolTip}>
                <div className="cursor-pointer flex justify-center items-center ">
                  <ToolTipSvg />
                </div>
              </Tooltip>
            )} */}
          </div>
        )}
      </div>
      <div className={`flex justify-center relative items-center`}>
        <input
          id={id}
          autoComplete='off'
          disabled={disabled}
          contentEditable={disabled}
          name={name}
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          accept={accept}
          onBlur={onBlur}
          className={` outline-none border p-2 text-xs rounded-small w-full ${
            isError || isApiError ? "border-app-danger" : "border-app-secondary"
          } ${className}`}
        />

        {isError && type === "password" ? (
          <div className='flex items-center gap-2 absolute right-4'>
            <span>
              <DangerWarning />
            </span>

            <button
              type='button'
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <HidePassword /> : <ShowPassword />}
            </button>
          </div>
        ) : isError ? (
          <span className='absolute right-4'>
            <DangerWarning />
          </span>
        ) : (
          type === "password" && (
            <button
              type='button'
              className='absolute right-4 '
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <HidePassword /> : <ShowPassword />}
            </button>
          )
        )}
      </div>
      {error && isError && (
        <span className='text-xs text-app-danger text-right'>{error}</span>
      )}
    </div>
  );
};

export default GlobalInput;
