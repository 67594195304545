import React from "react";
import { cn } from "../../../utils/cn";

const ProfileHeading = ({
  children,
  className,
}: {
  children: React.ReactNode | string;
  className?: string;
}) => {
  return (
    <div
      className={cn(
        `font-semibold text-[24px] text-white bg-transparent font-baumans`,
        className
      )}
    >
      {children}
    </div>
  );
};

export default ProfileHeading;
