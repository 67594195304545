import React from 'react'
import Icon, { IconType } from "../../utils/icons/Icon";
import { formatNumber } from '../../utils/number-formatter';
import { formatLargeNumber } from '../../utils/formate-large-number';
import { Link } from 'react-router-dom';
import Spinner from '../primary/spinner';
import { useAppContext } from '../../context-api';

interface MigrationRequestsCardProps {
  iconName: IconType;
  title: string;
  amount: number;
}
const MigrationRequestsCard: React.FC<MigrationRequestsCardProps> = ({ iconName, title, amount }) => {
  const { state } = useAppContext();
  const data = state?.migrationRequests;
  const formattedAmount = formatLargeNumber(amount);

  if (!data) {
    return <Spinner />;
  }

  return (
    <div className="bg-white border border-[#D9E4F1] px-7 py-5 rounded-xl space-y-5">
      <Icon icon={iconName} className="text-5xl" />
      <p className="text-md md:text-lg">{title}</p>
      <h3 className="font-semibold text-2xl md:text-4xl">{formattedAmount}</h3>

      <div className="pt-20">
        <Link to="/requests" className="flex items-center gap-1 justify-end hover:underline">
          View All <Icon icon="ArrowRight" className="text-sm" />
        </Link>
      </div>
    </div>
  );
};


export default MigrationRequestsCard
