import React from "react";
import GlobalButton from "../primary/global-elements/global-button";
import DownloadIcon from "../../utils/icons/download-btn.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUserActions } from "../../context-api/actions";
import { useAppContext } from "../../context-api";
import Spinner from "../primary/spinner";
import Icon from "../../utils/icons/Icon";
import { formatNumber } from "../../utils/number-formatter";
import PdfViewer from "./fetch-pdf";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const MigrateDetails: React.FC<{
  page?: number
}> = ({
  page = 1
}) => {
    const query = useQuery();
    const id: string | undefined | null = query.get("id");

    const { pathname = "" } = useLocation();
    const navigate = useNavigate();

    const { getSingleMigrateDetails, setMigrationDetailsNull, migrateRequests, getAllMigration } = useUserActions();
    const {
      state: { migrationDetails: details, migrationDetailsLoading },
    } = useAppContext();

    const fullName = `${details?.user?.firstName} ${details?.user?.lastName}` || "";
    const profilePhoto = details?.user?.profilePhoto?.url || "";
    const contractUrl = details?.contract?.url || "";
    const { fetchLoading, fetchPdf } = PdfViewer({ pdfUrl: contractUrl, name: fullName && fullName.length ? fullName.split(" ").join("-") : "" }); //details?.contract?.url

    const [loading, setLoading] = React.useState<boolean>(false);
    const [reasonTextArea, setReasonTextArea] = React.useState<boolean>(false);
    const [reason, setReason] = React.useState<string>("");
    const [error, setError] = React.useState<string>("");

    // SCROLL EFFECT CONTROL
    const [isScrolled, setIsScrolled] = React.useState<boolean>(false);
    const scrollRef = React.useRef<HTMLDivElement>(null);

    const handleApproveReject = async (value: "approved" | "rejected") => {
      if (id) {
        setError("");
        if (value === 'rejected' && reason === '') {
          setError("Reason is required!");
          return;
        }
        setLoading(true);
        try {
          const response = await migrateRequests({
            _id: id,
            status: value,
            reason,
          });

          if (response?.status === 200) {
            navigate(`${pathname}`);
            setReasonTextArea(false);
            setReason("")
            setError("")
            getAllMigration({ page });
          }

        } catch (error) {
          navigate(`${pathname}`);
          console.log(error);

        } finally {
          setLoading(true);
        }
      }
    }

    React.useEffect(() => {
      if (id) {
        getSingleMigrateDetails({ _id: id });
      }

      return () => {
        setMigrationDetailsNull();

        // reset all the states
        setReasonTextArea(false);
        setReason("")
        setError("")
      };
    }, [id]);


    const handleScroll = () => {
      if (scrollRef.current) {
        setIsScrolled(scrollRef.current.scrollTop > 0);
      }
    };

    React.useEffect(() => {
      const scrollElement = scrollRef.current;
      if (scrollElement) {
        scrollElement.addEventListener('scroll', handleScroll);
        return () => scrollElement.removeEventListener('scroll', handleScroll);
      }
    }, []);

    if (migrationDetailsLoading) {
      return (
        <div className='grid place-content-center'>
          <Spinner />
        </div>
      );
    }

    const formattedNumber = formatNumber(details?.totalReceived ?? 0);



    return (
      <div className=''>
        <div className='flex flex-col space-y-6 p-6 w-full'>
          <div className='flex flex-col bg-[#f3f3f3] rounded-small p-1'>
            <div className="flex space-x-2 p-2">
              <img
                src={profilePhoto}
                alt={fullName}
                className='rounded-small w-24 h-24 mb-4 border object-cover object-center'
              />
              <div className='flex flex-col w-full'>
                <div className="border-b-2 py-1 w-full">
                  <div className="flex items-center flex-wrap space-x-2">
                    <h2 className='text-xl font-semibold'>
                      {fullName}
                    </h2>
                    {
                      details?.status === 'rejected' ? (
                        <span>
                          <Icon icon='NotVerifyRed' className='w-5 h-5' />
                        </span>
                      )
                        : details?.status === 'approved' ? (
                          <span>
                            <Icon icon='VerifiedGreen' className='w-5 h-5' />
                          </span>
                        )
                          : null}
                  </div>
                  <p className='text-app-gray-400'>{details?.user?.email}</p>
                </div>

                <div className="flex justify-center flex-col space-y-2 w-full py-2">
                  <div className="flex items-center gap-3">
                    <p className=" text-sm text-app-gray-400">Nationality:</p>
                    <p className=" text-sm">{details?.user?.nationality}</p>
                  </div>
                  <div className="flex items-center gap-3">
                    <p className=" text-sm text-app-gray-400">Phone:</p>
                    <p className=" text-sm">{details?.user?.phone}</p>
                  </div>
                </div>

                <div className="flex items-center flex-wrap space-x-3">
                  <div className="flex items-center gap-3 p-1 bg-app-light rounded-small">
                    <p className=" text-sm text-app-gray-400">Capital Balance</p>
                    <p className=" text-sm">${formatNumber(details?.user?.capital ?? 0)}</p>
                  </div>
                  <div className="flex items-center gap-3 px-2 py-1 bg-app-light rounded-small">
                    <p className=" text-sm text-app-gray-400">Residue Amount</p>
                    <p className=" text-sm">${formatNumber(details?.user?.balance ?? 0)}</p>
                  </div>
                </div>

                {
                  details?.status === 'rejected' && details?.reason?.length ? (
                    <p className="text-sm border border-[#FFCDCA] bg-[#FFF3F2] text-app-gray-400 p-1 my-2 rounded-small">{details?.reason}</p>
                  )
                    : null}
              </div>
            </div>
          </div>

          {/*
         * Header section ends here
         */}

          <div className='border-b p-7 bg-[#F5F5F5] rounded-t-small'>
            <p className='text-black'>
              <span className='font-semibold'>Total Receivable:</span>
              <span className='text-xl ml-3 font-semibold bg-white border border-[#C6C6C6] py-1 px-3 rounded-lg text-center'>${formattedNumber !== "NaN" ? formattedNumber : "0"}</span>
            </p>
          </div>

          <div className={`h-48 overflow-x-auto small-scroll py-4 ${isScrolled ? 'shadow-inner' : ''}`} ref={scrollRef}>
            {details?.products?.length === 0 ? <div className='flex flex-col justify-center items-center space-y-4 h-[350px]'>
              <Icon icon='NoMlYk' className='w-60 h-60' />
              <p className='text-center text-gray-500'>No Balances</p> </div> : details?.products?.length ? details?.products?.map((product, index) => (
                <div
                  key={index}
                  className='flex items-center justify-between border-b px-2 py-[7px]'
                >
                  <div className="flex items-center space-x-3">
                    <div className='`overflow-hidden w-12 h-12 border rounded-lg'>
                      <img
                        src={product.imageUrl || ""}
                        alt={product.name}
                        className='w-full h-full object-cover object-center rounded-lg border'
                      />
                    </div>
                    <div className=''>
                      <h3 className='text-lg font-semibold'>{product.name}</h3>
                    </div>
                  </div>
                  <div className='flex flex-col gap-y-2'>
                    <p className='text-sm font-medium'>
                      $ {product.amountUSD}
                    </p>
                  </div>
                </div>
              )) : <p className="text-center">No Products Available</p>}
          </div>
          {reasonTextArea && (
            <div className="flex flex-col space-y-2">
              <div className="flex flex-col space-y-2">
                <textarea
                  className="border rounded p-2 w-full outline-none focus:ring-0 small-scroll"
                  placeholder="Enter reason for rejection"
                  rows={4}
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
                {error ? <p className="text-red-500 text-sm">{error}</p> : null}
              </div>

              <p className="text-sm">Reasons for rejection should be entered
              </p>
            </div>
          )}

          <div className='flex items-center justify-between py-3'>
            <div className="">
              {/* <a href={contractUrl} download target="_blank" rel="noreferrer"> */}

              <GlobalButton
                StartIcon={<Icon icon="EyeIcon" />}
                text='View Contract'
                classNames='!text-xs py-2 px-4 rounded-small border-gray-200'
                outlined
                loading={fetchLoading}
                onClick={() => fetchPdf()}
              />
              {/* </a> */}
            </div>
            <div className='flex space-x-3'>
              {loading ? <Spinner /> : null}
              <GlobalButton
                text='Approve'
                classNames='border-gray-200 py-2 px-4 rounded-small'
                onClick={() => handleApproveReject("approved")}
                disabled={loading || details?.status === 'approved' || details?.status === 'rejected'}
                outlined
              />
              {reasonTextArea ? <GlobalButton
                text='Submit'
                classNames='py-2 px-4 rounded-small hover:opacity-80'
                onClick={() => handleApproveReject("rejected")}
                disabled={loading || details?.status === 'approved' || details?.status === 'rejected'}
              /> : <GlobalButton
                text='Reject'
                classNames='py-2 px-4 rounded-small hover:opacity-80'
                onClick={async () => {
                  setReasonTextArea(true);
                }}
                disabled={loading || details?.status === 'approved' || details?.status === 'rejected'}
              />}
            </div>
          </div>
        </div>

        <style>{`
          .shadow-inner::before,
.shadow-inner::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 10px;
  pointer-events: none;
  z-index: 10;
}

.shadow-inner::before {
  top: 0;
  box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.5);
}

.shadow-inner::after {
  bottom: 0;
  box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.5);
}
        `}</style>
      </div>
    );
  };

export default MigrateDetails;
