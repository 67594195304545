import React from "react";

const GlobalTableSkeleton: React.FC<{
  count: number;
}> = ({ count }) => {
  return (
    <>
      <tbody>
        {[...Array(count)].map((_, index) => (
          <tr
            key={index}
            className='border-b border-app-gray-200 animate-pulse'
          >
            {[...Array(count)].map((_, c_index) => (
              <td className='px-6 py-4 whitespace-nowrap' key={c_index}>
                <div className='h-4 bg-gray-200 rounded'></div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </>
  );
};

export default GlobalTableSkeleton;
