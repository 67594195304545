import React, { useEffect, useRef } from "react";
import { useUserActions } from "../../context-api/actions";
import Spinner from "../primary/spinner";
import Modal from "../primary/modals";
import GlobalButton from "../primary/global-elements/global-button";

const CustomDropdown = ({ id, page, selectedvalue }: { id: string, page?: any, selectedvalue: "approved" | "rejected" | "pending" }) => {
    const { getAllMigration, migrateRequests } = useUserActions();
    const dropdownRef = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = React.useState(false);
    const [selected, setSelected] = React.useState('pending');
    const [loading, setLoading] = React.useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const [reason, setReason] = React.useState<string>("");
    const [error, setError] = React.useState<string>("");

    const handleSubmit = async (value: "approved" | "rejected") => {
        setError("");
        if (value === 'rejected' && reason === '') {
            setError("Reason is required!");
            return;
        }

        try {
            const response = await migrateRequests(
                {
                    _id: id,
                    status: value,
                    reason: reason
                }
            );

            if (response?.status === 200) {
                getAllMigration({ page });
                setSelected(value);
                setIsModalOpen(false);
                setReason("");
                setError("");
            }
        } catch (error) {
            // Handle error
            setError("An error occurred while processing your request.");
        } finally {
            setLoading(false);
        }
    }

    const handleSelect = async (value: "approved" | "rejected") => {

        setIsOpen(false);

        if (value === 'rejected') {
            setIsModalOpen(true);
            return;
        }

        handleSubmit(value);

        setLoading(true);

    };

    React.useEffect(() => {
        setSelected(selectedvalue);
    }, [selectedvalue]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="relative inline-block text-left" ref={dropdownRef}>
            <div>
                <button
                    type="button"
                    className={`${selected === 'approved' ? "text-green-500" : selected === 'rejected' ? "text-red-500" : "hover:bg-gray-50 "} capitalize inline-flex gap-2 justify-center w-full rounded-md border border-app-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium focus:outline-none`}
                    onClick={() => setIsOpen(!isOpen)}
                    disabled={loading || selected === 'approved' || selected === 'rejected' || id === ''}
                >
                    {loading ? <Spinner /> : null}
                    {selected}
                    {selected === "pending" ? <svg
                        className="-mr-1 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            fillRule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                    </svg> : null}
                </button>
            </div>

            {isOpen && (
                <ul className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-[1000]">
                    <li
                        className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
                        onClick={() => handleSelect('approved')}
                    >
                        Approved
                    </li>
                    <li
                        className="text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
                        onClick={() => handleSelect('rejected')}
                    >
                        Rejected
                    </li>
                </ul>
            )}

            {isModalOpen && (
                <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                    <div className="p-4">
                        <h2 className="text-gray-900"><span className="text-app-danger">*</span>Provide a reason</h2>
                        <textarea
                            className="mt-2 w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-0"
                            rows={4}
                            value={reason}
                            placeholder="Enter reason here..."
                            onChange={(e) => setReason(e.target.value)}
                        />
                        {error ? <p className="text-app-danger text-sm py-0.5">{error}</p> : null}
                        <div className="mt-4 flex justify-end">
                            <div className="mt-4 flex space-x-3 justify-end">
                                <GlobalButton
                                    text='Cancel'
                                    classNames='border-gray-200 py-2 px-4 rounded-small'
                                    onClick={() => {
                                        setError("");
                                        setIsModalOpen(false);
                                    }}
                                    disabled={loading}
                                    outlined
                                />
                                <GlobalButton
                                    text='Submit'
                                    classNames='py-2 px-4 rounded-small hover:opacity-80'
                                    onClick={() => handleSubmit("rejected")}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default CustomDropdown;
