import { useReducer, ReactNode } from "react"
import { AppContext, initialState } from "." 
import { reducer } from "./reducers"
// Create a provider component
export const AppProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  )
}
