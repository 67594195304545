import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import GlobalButton from "../primary/global-elements/global-button";
import GlobalCheckbox from "../primary/global-elements/global-checkbox";
import GlobalInput from "../primary/global-elements/global-input";
import { useUserActions } from "../../context-api/actions";
import { useAppContext } from "../../context-api";

const Signin = () => {
  const { userLogin } = useUserActions();
  const { state } = useAppContext();
  const { loginLoading } = state;

  const [error, setError] = React.useState<string | null>(null)

  interface FormValues {
    email: string;
    password: string;
    rememberPassword: boolean;
    captchaToken?: string;
  }

  const Login = async (values: FormValues) => {
    try {
      if (values.rememberPassword) {
        localStorage.setItem("valuesOfRememberMe", "true");
      }
      await userLogin(values);
    } catch (error: any) {
      console.log(error?.message);
      setError(error?.message)
    }

  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberPassword: false,
      captchaToken: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
      rememberPassword: Yup.boolean(),
      captchaToken: Yup.string().optional(),
    }),
    onSubmit: async (values: FormValues) => {
      await Login(values);
    },
  });

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const trimmedValue = value.trim().replace(/\s+/g, "");
    formik.setFieldValue("email", trimmedValue);
  };

  return (
    <>
      <div className='w-full flex-col gap-10 justify-center'>
        {error && (
          <div className='text-center text-xs bg-red-100 p-3 rounded-xl mb-10'>
            <p className="text-red-500">
              {error}
            </p>
          </div>
        )}
        <form
          onSubmit={formik.handleSubmit}
          className='w-full p-4 flex flex-col gap-10 justify-center'
        >
          <h1 className='text-xl'>Sign in</h1>
          <div className='w-full flex flex-col gap-4'>
            {Object.keys(formik.initialValues).map((key) =>
              key !== "rememberPassword" && key !== "captchaToken" ? (
                <GlobalInput
                  key={key}
                  name={key}
                  label={key.charAt(0).toUpperCase() + key.slice(1)}
                  placeholder={`Enter your ${key}`}
                  type={key === "password" ? "password" : "text"}
                  required
                  value={formik.values[key as keyof FormValues]}
                  onChange={
                    key === "email" ? handleEmailChange : formik.handleChange
                  }
                  isError={formik.errors[key as keyof FormValues]}
                  error={formik.errors[key as keyof FormValues]}
                />
              ) : null
            )}
            <GlobalCheckbox
              checked={formik.values.rememberPassword}
              setChecked={(value) =>
                formik.setFieldValue("rememberPassword", value)
              }
              label='Remember Me'
            />
          </div>
          <div className='flex flex-col gap-2'>
            <GlobalButton
              text='Sign in'
              type='submit'
              loading={loginLoading}
              disabled={loginLoading}
              classNames='!w-full'
              isSignIn
              secondary
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default Signin;
