import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import ContainerComponent from "../primary/container";
import AppHeader from "../primary/app-header";
import AppSidebar from "../primary/sidebar";
import BottomBar from "../primary/bottom-bar";

const Layout: React.FC = () => {
  const location = useLocation();
  const pathname = location.pathname;

  const [isCollapsed, setIsCollapsed] = React.useState(true);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const title = pathname === "/" ? "dashboard" : pathname.replace("/", "");

  return (
    <div className="">
      <AppHeader
        isCollapsed={isCollapsed}
        onCollapsedClick={toggleSidebar}
        title={title}
      />
      <main className='flex justify-center w-full'>
        <AppSidebar
          onClose={toggleSidebar}
          isCollapsed={isCollapsed}
          currentPath={pathname}
        />



        <section
          className={`min-h-[calc(100vh-80px)] overflow-hidden w-full  bg-white flex flex-col gap-y-2 app-transition-all-300 ${isCollapsed ? "ml-4 md:ml-10" : ""
            } w-full`}
        >
          <ContainerComponent>
            <Outlet />
          </ContainerComponent>
        </section>
      </main>
      {isCollapsed ? (
        /*
         * THIS IS A BACKDROP FOR MOBILE VIEW
         */
        <div
          className='md:hidden z-[48] fixed inset-0 bg-black opacity-50'
          onClick={toggleSidebar}
        ></div>
      ) : null}
      <BottomBar />
    </div>
  );
};

export default Layout;
