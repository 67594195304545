//AXIOS
import axios from "axios";
import { base_url } from "../constants";
import currentStorage from "../currentStorage";

export const ApiRequest = () => {
  const request = axios.create({
    baseURL: base_url,
    headers: {
      Authorization: `Bearer ${currentStorage().getItem("token") || ""}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    responseType: "json",
    socketPath: null,
    withCredentials: false,
  });

  request.interceptors.response.use(
    (response: any) => {
      return response;
    },
    (error: any) => {
      if (error.code === "ERR_NETWORK") {
        return;
      }

      if (error.response.status === 403) {
        return;
      }

      return Promise.reject(error.response);
    }
  );

  return request;
};
