import React, { ReactNode } from "react";
import { cn } from "../../utils/cn";

const ContainerComponent: React.FC<{
  children: ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return <div className={cn("p-4", className)}>{children}</div>;
};

export default ContainerComponent;
