import "./App.css"
import { RouterProvider } from "react-router-dom"
import { router } from "./routes"
import { ToastProvider } from "./utils/app-toast"

function App() {
  return (
    <>
      {/* 
    // ? ADD Toaster HERE 
    */}
      <ToastProvider>
        <RouterProvider router={router} />
      </ToastProvider>
    </>
  )
}

export default App
