import React from "react";
import ContainerComponent from "../container";
import Icon from "../../../utils/icons/Icon";
import AppLogoComponent from "./app-logo";
import UserDropdown from "./user-dropdown";
import { useUserActions } from "../../../context-api/actions";

interface AppHeaderProps {
  title: string;
  isCollapsed: boolean;
  onCollapsedClick: () => void;
}

const AppHeader: React.FC<AppHeaderProps> = ({
  title,
  isCollapsed,
  onCollapsedClick,
}) => {
  const { getAdminProfile } = useUserActions();

  React.useEffect(() => {
    getAdminProfile();
  }, []);

  return (
    <header className='border-b border-b-app-primary/10 bg-app-dark sticky top-0 z-[99]'>
      <ContainerComponent className='flex items-center space-x-3 md:justify-between'>
        <div className='flex items-center justify-between md:justify-normal space-x-4 w-full'>
          <AppLogoComponent />
          <button
            onClick={onCollapsedClick}
            className='p-2 text-sm'
          >
            <Icon icon='HamburgerIcon' className='h-4 w-4 !text-app-light' />
          </button>
          {/* <h1 className='capitalize text-2xl'>{title}</h1> */}
        </div>
        <UserDropdown />
      </ContainerComponent>
    </header>
  );
};

export default AppHeader;
