import React from "react";

interface LoaderProps {
  size?: "small" | "medium" | "large";
  color?: string;
}

const Loader: React.FC<LoaderProps> = ({
  size = "small",
  color = "#F8971D",
}) => {
  return (
    <div
      className={`animate-spin ${
        size === "small"
          ? "h-4 w-4"
          : size === "large"
          ? "h-12 w-12"
          : "h-8 w-8"
      }`}
      style={{ borderColor: color, borderTopColor: "transparent" }}
    >
      <div className="border-4 border-solid rounded-full border-app-primary"></div>
    </div>
  );
};

export default Loader;
