import React from "react";
import { Link } from "react-router-dom";
import Icon from "../../../utils/icons/Icon";

const AppLogoComponent: React.FC = () => {
  return (
    <Link to={`/`}>
      <div className='bg-black px-2'>
        <Icon icon='AppLogo' className='h-10 w-32' />
      </div>
    </Link>
  );
};

export default AppLogoComponent;
