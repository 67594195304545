import React from "react";
import { useLocation } from "react-router-dom";
import { useUserActions } from "../../context-api/actions";
import { useAppContext } from "../../context-api";
import Spinner from "../primary/spinner";
import { formatNumber } from "../../utils/number-formatter";
import Product from "./product-card";
import Icon from "../../utils/icons/Icon";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const UserDetailsModal: React.FC<{
    page?: number
}> = ({
    page = 1
}) => {
        const query = useQuery();
        const id: string | undefined | null = query.get("id");

        const { getUserDetails, setUserDetailsNull } = useUserActions();
        const {
            state: { userDetails: details, userDetailsLoading },
        } = useAppContext();

        React.useEffect(() => {
            if (id) {
                getUserDetails({ _id: id });
            }

            return () => {
                setUserDetailsNull();
            };
        }, [id]);


        if (userDetailsLoading) {
            return (
                <div className='grid place-content-center'>
                    <Spinner />
                </div>
            );
        }

        const formattedNumber = formatNumber(details?.totalReceived ?? 0);
        const profilePhoto = details?.profilePhoto?.url || "";
        const fullName = `${details?.firstName} ${details?.lastName}` || "";

        return (
            <div className='grid space-y-3 p-2'>
                <div className='flex flex-col bg-[#f3f3f3] rounded-small'>
                    <div className="flex space-x-2 p-2">
                        <img
                            src={profilePhoto}
                            alt={fullName}
                            className='rounded-small w-24 h-24 mb-4 border object-cover object-center'
                        />
                        <div className='flex flex-col w-full'>
                            <div className="border-b-2 py-1 w-full">
                                <div className="flex items-center flex-wrap space-x-2">
                                    <h2 className='text-xl font-semibold'>
                                        {fullName}
                                    </h2>
                                    {
                                        details?.applicationStatus === 'rejected' ? (
                                            <span>
                                                <Icon icon='NotVerifyRed' className='w-5 h-5' />
                                            </span>
                                        )
                                            : details?.applicationStatus === 'approved' ? (
                                                <span>
                                                    <Icon icon='VerifiedGreen' className='w-5 h-5' />
                                                </span>
                                            )
                                                : null}
                                </div>
                                <p className='text-app-gray-400'>{details?.email}</p>
                            </div>

                            <div className="flex justify-center flex-col space-y-2 w-full py-2">
                                <div className="flex items-center gap-3">
                                    <p className=" text-sm text-app-gray-400">Nationality:</p>
                                    <p className=" text-sm">{details?.nationality}</p>
                                </div>
                                <div className="flex items-center gap-3">
                                    <p className=" text-sm text-app-gray-400">Phone:</p>
                                    <p className=" text-sm">{details?.phone}</p>
                                </div>
                            </div>

                            <div className="flex items-center flex-wrap space-x-3">
                                <div className="flex items-center gap-3 p-1 bg-app-light rounded-small">
                                    <p className=" text-sm text-app-gray-400">Capital Balance</p>
                                    <p className=" text-sm">${formatNumber(details?.capital ?? 0)}</p>
                                </div>
                                <div className="flex items-center gap-3 px-2 py-1 bg-app-light rounded-small">
                                    <p className=" text-sm text-app-gray-400">Residue Amount</p>
                                    <p className=" text-sm">${formatNumber(details?.balance ?? 0)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col space-y-6 w-full'>
                    <div className='w-full'>
                        <div className='border-b p-7 bg-[#F5F5F5] rounded-t-small'>
                            <p className='text-black'>
                                <span className='font-semibold'>Total Receivable:</span>
                                <span className='text-xl ml-3 font-semibold bg-white border border-[#C6C6C6] py-1 px-3 rounded-lg text-center'>${formattedNumber !== "NaN" ? formattedNumber : "0"}</span>
                            </p>
                        </div>

                        <div className=''>
                            {details?.products?.length === 0 ? <div className='flex flex-col justify-center items-center space-y-4 h-[350px]'>
                                <Icon icon='NoMlYk' className='w-60 h-60' />
                                <p className='text-center text-gray-500'>This user doesn't have Balance to Migrate</p> </div> : <ul className={(details?.products?.length) === 0 ? '' : 'h-[350px] overflow-y-scroll small-scroll'}>
                                {userDetailsLoading ? <div className='flex justify-center items-center h-full'> <Spinner /> </div> :
                                    details?.products != null && details?.products?.map((product: any, index: any) => (
                                        <li key={index} className='border-b pb-2'>
                                            <Product name={product?.name} image={product?.imageUrl} price={product?.amount} quantity={product?.qty} />
                                        </li>
                                    ))
                                }
                            </ul>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    };

export default UserDetailsModal;
