import React from "react";
import { Link } from "react-router-dom";
import Icon, { IconType } from "../../../utils/icons/Icon";

interface SidebarProps {
  isCollapsed: boolean;
  currentPath?: string;
  onClose: () => void;
}

const routes: {
  icon?: IconType;
  label: string;
  route: string;
}[] = [
    {
      icon: "DashboadIcon" as IconType,
      label: "Dashboard",
      route: "/",
    },
    {
      icon: "MigrationsIcon" as IconType,
      label: "Migrate Requests",
      route: "/requests",
    },
    {
      icon: "People" as IconType,
      label: "Users",
      route: "/users",
    },
  ];

const AppSidebar: React.FC<SidebarProps> = ({
  isCollapsed,
  currentPath = "",
  onClose,
}) => {
  return (
    <aside
      className={`sticky top-[73px] left-0 h-[calc(100vh-73px)] app-transition-all-300 bg-app-gray-100 border-r z-[49] max-[768px]:fixed ${isCollapsed ? "w-80 translate-x-0" : "w-0 -translate-x-52 "
        }`}
      tabIndex={-1}
    >
      <ul className='flex flex-col space-y-2 py-2 px-5'>
        {routes.map(
          (
            item: {
              icon?: IconType;
              label: string;
              route: string;
            },
            _idx: number
          ) => (
            <li key={_idx}>
              <Link
                className={`${currentPath && currentPath === item?.route
                  ? "text-app-light bg-app-dark"
                  : ""
                  } group flex items-center gap-2 text-sm hover:text-app-light hover:bg-app-dark/80 p-2 rounded-lg text-nowrap`}
                to={item.route}
              >
                <Icon icon={item.icon as IconType} className={`${currentPath && currentPath === item?.route
                  ? "!fill-app-primary"
                  : ""
                  } group-hover:fill-app-primary fill-app-dark`} />
                {item.label}
              </Link>
            </li>
          )
        )}
      </ul>
    </aside>
  );
};

export default AppSidebar;
