import React from 'react';

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const GobalPagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePageClick = (page: number) => {
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 3;
    const halfMaxPageNumbersToShow = Math.floor(maxPageNumbersToShow / 2);

    let startPage = Math.max(1, currentPage - halfMaxPageNumbersToShow);
    let endPage = Math.min(totalPages, currentPage + halfMaxPageNumbersToShow);

    if (currentPage <= halfMaxPageNumbersToShow) {
      endPage = Math.min(totalPages, maxPageNumbersToShow);
    }

    if (currentPage + halfMaxPageNumbersToShow >= totalPages) {
      startPage = Math.max(1, totalPages - maxPageNumbersToShow + 1);
    }

    if (startPage > 1) {
      pageNumbers.push(
        <button key={1} onClick={() => handlePageClick(1)} className="px-3 py-1 border rounded-lg hover:bg-app-light text-app-dark bg-app-gray-300 app-transition-all-300">
          1
        </button>
      );
      if (startPage > 2) {
        pageNumbers.push(<span key="start-ellipsis" className="px-3 py-1 border rounded-lg">...</span>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageClick(i)}
          className={`px-3 py-1 border rounded-lg hover:bg-app-light text-app-dark ${currentPage === i ? "bg-app-light" : "bg-app-gray-300"} app-transition-all-300`}
        >
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(<span key="end-ellipsis" className="px-3 py-1 border rounded-lg">...</span>);
      }
      pageNumbers.push(
        <button key={totalPages} onClick={() => handlePageClick(totalPages)} className="px-3 py-1 border rounded-lg hover:bg-app-light text-app-dark bg-app-gray-300 app-transition-all-300">
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="flex items-center space-x-2">
      <button onClick={handlePrevious} disabled={currentPage === 1} className="px-3 py-1 border rounded-lg hover:bg-app-light text-app-dark bg-app-gray-300 app-transition-all-300">
        Previous
      </button>
      {renderPageNumbers()}
      <button onClick={handleNext} disabled={currentPage === totalPages} className="px-3 py-1 border rounded-lg hover:bg-app-light text-app-dark bg-app-gray-300 app-transition-all-300">
        Next
      </button>
    </div>
  );
};

export default GobalPagination;