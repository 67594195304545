import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import Layout from "../components/layout";
import Loader from "../components/primary/loader";
import AuthLayout from "../components/auth/auth-layout";
import Signin from "../components/auth/sign-in";
import Profile from "../components/profile";
import Users from "../pages/users";
import MigrationRequests from "../components/requests";
import ProtectedRoute from "../components/protected-component";
import AuthProtectedRoute from "../components/auth-protected-component";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <React.Suspense fallback={<>Loading...</>}>
        <ProtectedRoute>
          <Layout />
        </ProtectedRoute>
      </React.Suspense>
    ),
    children: [
      {
        path: "/",
        element: (
          <React.Suspense fallback={<>Loading...</>}>
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          </React.Suspense>
        ),
      },
      {
        path: "profile",
        element: (
          <React.Suspense fallback={<Loader />}>
            <Profile />
          </React.Suspense>
        ),
      },
      {
        path: "users",
        element: (
          <React.Suspense fallback={<Loader />}>
            <Users />
          </React.Suspense>
        ),
      },
      {
        path: "requests",
        element: (
          <React.Suspense fallback={<Loader />}>
            <MigrationRequests />
          </React.Suspense>
        ),
      },
    ],
  },

  {
    path: "/auth",
    element: (
      <React.Suspense fallback={<Loader />}>
        <AuthProtectedRoute>
          <AuthLayout />
        </AuthProtectedRoute>
      </React.Suspense>
    ),

    children: [
      {
        path: "signin",
        element: (
          <React.Suspense fallback={<Loader />}>
            <AuthProtectedRoute>
              <Signin />
            </AuthProtectedRoute>
          </React.Suspense>
        ),
      },
      // {
      //   path: "forgot-password",
      //   element: (
      //     <React.Suspense fallback={<Loader />}>
      //       <ForgetPassword />
      //     </React.Suspense>
      //   ),
      // },
      // {
      //   path: "verify-account",
      //   element: (
      //     <React.Suspense fallback={<Loader />}>
      //       <VerifyEmail />
      //     </React.Suspense>
      //   ),
      // },
      // {
      //   path: "verify-2fa",
      //   element: (
      //     <React.Suspense fallback={<Loader />}>
      //       <Verify2FA />
      //     </React.Suspense>
      //   ),
      // },
      // {
      //   path: "email-send",
      //   element: (
      //     <React.Suspense fallback={<Loader />}>
      //       <EmailSent />
      //     </React.Suspense>
      //   ),
      // },
      // {
      //   path: "reset-password",
      //   element: (
      //     <React.Suspense fallback={<Loader />}>
      //       <ResetPassword />
      //     </React.Suspense>
      //   ),
      // },

      {
        path: "*",
        element: (
          <React.Suspense fallback={<Loader />}>
            <Navigate to={`/auth/signin`} />
          </React.Suspense>
        ),
      },
    ],
  },

  {
    path: "*",
    element: <Navigate to='/' />,
    // element: (
    //   <React.Suspense fallback={<Loader />}>
    //     <Error />
    //   </React.Suspense>
    // ),
  },
]);
