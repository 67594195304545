import { useNavigate } from "react-router-dom";
import { useAppContext } from ".";
import { LOGGEDINUSER } from "../utils/constants";
import currentStorage from "../utils/currentStorage";
import {
  ADMIN_LOGOUT,
  ADMIN_USERS,
  ALL_MIGRATIONS,
  DASHBOARD,
  SIGNIN,
  USER_INFO,
} from "../utils/networks/api-endpoints";
import { ApiRequest } from "../utils/networks/api-request";
import { useAppToast } from "../utils/app-toast";

//* Define the shape of the state

// ? adminName:"SuperAdmin"
// ? email:"admin2@admin.com"
// ? firstName:"Admin"
// ? lastName:"User"
// ? role:"admin"
// ? _id : ""
export interface User {
  adminName: string;
  email: string;
  firstName: string;
  lastName: string;
  role: string;
  phone: string;
  profilePhoto: any;
  _id?: string;
}

/**
 * 
 * 
 * ? ALL users types
 * 
 *         
      applicationStatus : string
      balance: number
      capital: number
      email : string
      firstName:string
      lastName:string
      nationality:string
      phone:string
      profilePhoto:string
      role:string
      userName:string
      _id: string
 * 
 */
export interface IApplicationUser {
  applicationStatus: string;
  blockchainId: string;
  balance: number;
  capital: number;
  email: string;
  firstName: string;
  lastName: string;
  nationality: string;
  phone: string;
  profilePhoto: any;
  role: string;
  userName: string;
  _id?: string;
  createdAt: string;
  products: any[];
  totalReceived: number;
}

export const initialUserObj = {
  users: [] as IApplicationUser[],
  total: 0,
  page: 0,
  limit: 0,
  totalPages: 0,
};

export interface AllUsersWithPagination {
  users: IApplicationUser[] | [];
  total: number;
  page: number;
  limit: number;
  totalPages: number;
}

// ? Migration LISTS and Details types

export interface IMigrationValue {
  products: any[];
  user: {
    _id: string;
    blockchainId: string;
    userName: string;
    email: string;
    firstName: string;
    lastName: string;
    balance: number;
    capital: number;
    profilePhoto: any;
    phone: string;
    nationality: string;
    role: string;
    applicationStatus: string;
    createdAt: string;
  };
  reason?: string;
  contract: any;
  sig: any;
  kyc: any;
  status: string;
  _id?: string;
  createdAt: string;
  totalReceived: number;
}

export interface DashboardProps {
  totalUsers: number;
  migrationRequests: {
    rejected: number;
    pending: number;
    approved: number;
    total: number;
  };
  balances: {
    capital: number;
    balance: number;
    migrated: number;
  };
}
export interface AllMigrationsWithPagination {
  requests: IMigrationValue[] | [];
  total: number;
  page: number;
  limit: number;
  totalPages: number;
}

export const initialMigrationObj = {
  requests: [] as IMigrationValue[],
  total: 0,
  page: 0,
  limit: 0,
  totalPages: 0,
};

export const initialDashboardObj = {
  totalUsers: 0,
  migrationRequests: {
    rejected: 0,
    pending: 0,
    approved: 0,
    total: 0,
  },
  balances: {
    capital: 0,
    balance: 0,
    migrated: 0,
  },
};

export interface State {
  // * API fetch loading
  fetchLoading: boolean;
  // Add your state properties here
  loginLoading: boolean;
  logoutLoading: boolean;
  user: User | null;
  applicationUsers: AllUsersWithPagination | typeof initialUserObj;

  userDetailsLoading: boolean;
  userDetails: IApplicationUser | null;

  migrationDetailsLoading: boolean;
  migrationRequests: AllMigrationsWithPagination | typeof initialMigrationObj;
  migrationDetails: IMigrationValue | null;

  //*Dashboard state
  dashboardData: DashboardProps;
}

// * Define the action types
export const ACTIONS = {
  SET_FETCH_LOADING: "SET_FETCH_LOADING",

  SET_ADMIN: "SET_ADMIN",
  SET_SIGNIN_LOADING: "SET_SIGNIN_LOADING",
  SET_LOGOUT_LOADING: "SET_LOGOUT_LOADING",
  CLEAR_USER: "CLEAR_USER",

  //* Users action types here
  SET_ALL_USERS: "SET_ALL_USERS",
  SET_USER_DETAILS: "SET_USER_DETAILS",
  SET_USER_DETAILS_LOADING: "SET_USER_DETAILS_LOADING",

  //* Migrations action types here
  SET_ALL_MIGRATIONS: "SET_ALL_MIGRATIONS",
  SET_MIGRATION_DETAILS: "SET_MIGRATION_DETAILS",
  SET_MIGRATION_DETAILS_LOADING: "SET_MIGRATION_DETAILS_LOADING",

  //* Dashboard action types here
  GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
};

//* Define the shape of the actions
export type Action = { type: typeof ACTIONS.SET_ADMIN; payload: any };

//* Define the action creators
// ? APPLICATION ACTIONS
export const setFetchLoading = (payload: boolean): Action => ({
  type: ACTIONS.SET_FETCH_LOADING,
  payload,
});

//* AUTH ACTIONS
export const setUser = (payload: User | null): Action => ({
  type: ACTIONS.SET_ADMIN,
  payload,
});

export const setLoginLoading = (payload: boolean): Action => ({
  type: ACTIONS.SET_SIGNIN_LOADING,
  payload,
});

export const setLogoutLoading = (payload: boolean): Action => ({
  type: ACTIONS.SET_LOGOUT_LOADING,
  payload,
});

//* USERS ACTIONS

export const setAllUsers = (payload: AllUsersWithPagination): Action => ({
  type: ACTIONS.SET_ALL_USERS,
  payload,
});

export const setUserDetailsLoading = (payload: boolean): Action => ({
  type: ACTIONS.SET_USER_DETAILS_LOADING,
  payload,
});

export const setSingleUser = (payload: IApplicationUser): Action => ({
  type: ACTIONS.SET_USER_DETAILS,
  payload,
});

//* MIGRATIONS ACTIONS
export const setMigrationDetailsLoading = (payload: boolean): Action => ({
  type: ACTIONS.SET_MIGRATION_DETAILS_LOADING,
  payload,
});

export const setAllMigrations = (
  payload: AllMigrationsWithPagination
): Action => ({
  type: ACTIONS.SET_ALL_MIGRATIONS,
  payload,
});

export const setMigrationDetails = (payload: IMigrationValue): Action => ({
  type: ACTIONS.SET_MIGRATION_DETAILS,
  payload,
});

//*DASHBOARD ACTIONS
export const fetchDashboardData = (payload: any): Action => ({
  type: ACTIONS.GET_DASHBOARD_DATA,
  payload,
});

export const useUserActions = () => {
  const { state, dispatch } = useAppContext();
  const navigate = useNavigate();
  const { addToast, addAppCustomToast } = useAppToast();

  const logoutFunc = (status?: number) => {
    const storage = currentStorage();
    storage.removeItem(LOGGEDINUSER);
    storage.removeItem("token");
    storage.removeItem("valuesOfRememberMe");

    const message =
      status === 401 ? "Session expired" : "You have been logged out.";

    addAppCustomToast(message, {
      appearance: "info",
      type: "info",
    });
    dispatch(setUser(null));
    navigate("/auth/signin");
  };

  const userLogin = async ({ email, password, captchaToken }: any) => {
    const storage = currentStorage();
    dispatch(setLoginLoading(true));
    try {
      const response = await ApiRequest().request({
        method: "POST",
        url: SIGNIN,
        data: {
          email: email,
          password: password,
        },
      });
      if (response.status === 200) {
        dispatch(setUser(response.data.result || {}));
        const token = response.headers.authorization.replace("Bearer ", "");
        storage.setItem("token", token);
        storage.setItem(LOGGEDINUSER, "true");
        navigate("/");
      }
    } catch (error: any) {
      if (error.status === 401) {
        logoutFunc(error.status);
      }
      throw new Error(
        error?.data?.message || "Something went wrong. Please try again later."
      );
    } finally {
      dispatch(setLoginLoading(false));
    }
  };

  const getAdminProfile = async () => {
    try {
      const response = await ApiRequest().request({
        method: "GET",
        url: USER_INFO,
      });
      if (response.status === 200) {
        dispatch(setUser(response?.data?.result || null));
      }
    } catch (error: any) {
      if (error.status === 401) {
        logoutFunc(error.status);
      }
    } finally {
      dispatch(setLoginLoading(false));
    }
  };

  const updateAdminProfile = async (data: {
    email?: string;
    username?: string;
    firstName: string;
    lastName: string;
    phone: string;
    profilePhoto?: any;
  }) => {
    try {
      const response = await ApiRequest().request({
        method: "PATCH",
        url: USER_INFO,
        data,
      });
      if (response.status === 200) {
        dispatch(setUser(response?.data?.result || null));
        addToast("Profile updated successfully", {
          appearance: "success",
          type: "success",
        });
      }
      return response;
    } catch (error: any) {
      if (error.status === 401) {
        logoutFunc(error.status);
      }
    } finally {
      dispatch(setLoginLoading(false));
    }
  };

  const userInfoFromState = () => {
    const storage = currentStorage();
    const isLoggedin = storage.getItem(LOGGEDINUSER);

    if (isLoggedin === "true" && state.user) {
      return state.user;
    }

    return null;
  };

  const userLogout = async () => {
    dispatch(setLogoutLoading(true));
    try {
      const response = await ApiRequest().request({
        method: "POST",
        url: ADMIN_LOGOUT,
      });
      if (response.status === 200) {
        logoutFunc();
      }
    } catch (error: any) {
      if (error.status === 401) {
        logoutFunc(error.status);
      }
    } finally {
      dispatch(setLogoutLoading(false));
    }
  };

  const getAllMigration = async (params: {
    page: number;
    query?: string;
    status?: string;
  }) => {
    const { page, query = "", status = "" } = params;
    dispatch(setFetchLoading(true));
    try {
      const response = await ApiRequest().request({
        method: "GET",
        url: `${ALL_MIGRATIONS}?limit=10&page=${page}&query=${query}&status=${status}`,
      });

      if (response.status === 200) {
        dispatch(
          setAllMigrations(
            response?.data?.result ||
              (initialMigrationObj as AllMigrationsWithPagination)
          )
        );
      }
    } catch (error: any) {
      dispatch(
        setAllMigrations(initialMigrationObj as AllMigrationsWithPagination)
      );
      if (error.status === 401) {
        logoutFunc(error.status);
      }
    } finally {
      dispatch(setFetchLoading(false));
    }
  };

  const getSingleMigrateDetails = async (params: { _id: string }) => {
    const { _id } = params;

    dispatch(setMigrationDetailsLoading(true));

    try {
      const response = await ApiRequest().request({
        method: "GET",
        url: `${ALL_MIGRATIONS}/${_id}`,
      });

      if (response.status === 200) {
        dispatch(
          setMigrationDetails(response?.data?.result || ({} as IMigrationValue))
        );
      }
    } catch (error: any) {
      dispatch(setMigrationDetails({} as IMigrationValue));
      if (error.status === 401) {
        logoutFunc(error.status);
      }
    } finally {
      dispatch(setMigrationDetailsLoading(false));
    }
  };

  const migrateRequests = async (params: {
    _id: string;
    status: "pending" | "approved" | "rejected" | null;
    reason: string;
  }) => {
    const { _id, status = null, reason = "" } = params;

    try {
      const response = await ApiRequest().request({
        method: "PATCH",
        url: `${ALL_MIGRATIONS}/${_id}`,
        data: {
          status,
          reason,
        },
      });

      if (response.status === 200) {
        dispatch(
          setMigrationDetails(response?.data?.result || ({} as IMigrationValue))
        );
        addToast("You have been updated migration status successfully", {
          appearance: "success",
          type: "success",
        });
        return response;
      }
    } catch (error: any) {
      // dispatch(setMigrationDetails({} as IMigrationValue));
      if (error.status === 401) {
        logoutFunc(error.status);
      }
    }
  };

  const setMigrationDetailsNull = () => {
    dispatch(setMigrationDetailsLoading(false));
    dispatch(setMigrationDetails({} as IMigrationValue));
  };

  const getAllUsers = async (params: { page: number; query?: string }) => {
    const { page, query = "" } = params;

    dispatch(setFetchLoading(true));

    try {
      const response = await ApiRequest().request({
        method: "GET",
        url: `${ADMIN_USERS}?limit=10&page=${page}&query=${query}`,
      });

      if (response.status === 200) {
        dispatch(
          setAllUsers(
            response?.data?.result || (initialUserObj as AllUsersWithPagination)
          )
        );
      }
    } catch (error: any) {
      dispatch(setAllUsers(initialUserObj as AllUsersWithPagination));
      if (error.status === 401) {
        logoutFunc(error.status);
      }
    } finally {
      dispatch(setFetchLoading(false));
    }
  };

  const getUserDetails = async (params: { _id: string }) => {
    const { _id } = params;

    dispatch(setUserDetailsLoading(true));

    try {
      const response = await ApiRequest().request({
        method: "GET",
        url: `${ADMIN_USERS}/${_id}`,
      });

      if (response.status === 200) {
        dispatch(
          setSingleUser(response?.data?.result || ({} as IApplicationUser))
        );
      }
    } catch (error: any) {
      dispatch(setSingleUser({} as IApplicationUser));
      if (error.status === 401) {
        logoutFunc(error.status);
      }
    } finally {
      dispatch(setUserDetailsLoading(false));
    }
  };

  const setUserDetailsNull = () => {
    dispatch(setUserDetailsLoading(false));
    dispatch(setSingleUser({} as IApplicationUser));
  };

  const getDashboardDetails = async () => {
    dispatch(setFetchLoading(true));
    try {
      const response = await ApiRequest().request({
        method: "GET",
        url: `${DASHBOARD}`,
      });
      if (response.status === 200) {
        dispatch(
          fetchDashboardData(response?.data?.result || ({} as DashboardProps))
        );
        console.log("response dashboard", response);
      }
    } catch (error: any) {
      if (error.status === 401) {
        logoutFunc(error.status);
      }
    } finally {
      dispatch(setFetchLoading(false));
    }
  };

  return {
    userLogin,
    userLogout,
    userInfoFromState,
    getAdminProfile,
    getAllUsers,
    getUserDetails,
    setUserDetailsNull,
    getAllMigration,
    getSingleMigrateDetails,
    setMigrationDetailsNull,
    migrateRequests,
    getDashboardDetails,
    updateAdminProfile,
  };
};
