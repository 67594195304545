import React from "react";
import Spinner from "../spinner";

type Props = {
  text: string;
  type?: "button" | "submit" | "reset" | undefined;
  classNames?: string;
  onClick?: any;
  loading?: boolean;
  StartIcon?: React.ReactNode;
  secondary?: boolean;
  outlined?: boolean;
  disabled?: boolean;
  animate?: boolean;
  isSignIn?: boolean;
  isVerify?: boolean;
  position?: string;
};

const GlobalButton = ({
  text,
  type,
  classNames,
  onClick,
  loading,
  StartIcon,
  secondary = false,
  outlined = false,
  disabled = false,
  isSignIn = false,
  animate = true,
  position = "right",
}: Props) => {
  return (
    <button
      className={`w-auto min-h-10 ${position === "center"
        ? "mx-auto"
        : position === "left"
          ? "mr-auto"
          : "ml-auto"
        } flex gap-2  ${disabled || (loading && !isSignIn)
          ? "cursor-not-allowed bg-gray-200 opacity-50 border-gray-200"
          : "cursor-pointer"
        }
          ${secondary
          ? `!bg-app-dark border !border-app-primary`
          : `bg-app-primary`
        } 
      ${outlined ? `bg-white border border-app-primary hover:opacity-80 disabled:pointer-events-none` : `bg-app-primary`
        } rounded-full items-center justify-center py-3 ${outlined ? `text-app-dark` : `!text-white`
        }
      font-medium relative overflow-hidden outline-none ${!disabled &&
        !outlined &&
        animate &&
        `after:bg-gradient-to-r after:from-primary after:via-app-primary/60 after:via-80%
    after:to-app-primary/60 after:w-96 after:h-96 after:-rotate-45 after:absolute after:-right-96 hover:after:right-10 after:transition-all  after:ease-in-out after:duration-700`
        }
      ${loading && "after:right-10 after:animate-pulse"
        } h-9 md:h-auto whitespace-nowrap w-[8%] rounded-lg font-normal text-sm  py-[8px] px-5 
      min-w-max place-item-end ${classNames} app-transition-all-300`}
      type={type ? type : "button"}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading ? <Spinner /> : null}
      {StartIcon && <span>{StartIcon}</span>}
      <span
        className={`text-sm ${outlined ? `text-app-dark` : `!text-white`
          } relative !z-10 app-transition-all-300`}
      >
        {text}
      </span>
    </button>
  );
};

export default GlobalButton;
