import React, { createContext, useContext } from "react";
import { Action, initialDashboardObj, initialMigrationObj, initialUserObj, State } from "./actions";

// Define the initial state
export const initialState: State = {
  fetchLoading: false,
  logoutLoading: false,
  loginLoading: false,
  user: null,

  applicationUsers: initialUserObj,
  userDetails: null,
  userDetailsLoading: false,

  migrationRequests: initialMigrationObj,
  migrationDetailsLoading: false,
  migrationDetails: null,

  dashboardData:initialDashboardObj,
};

// Create the context
export const AppContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

// Create a custom hook to use the context
export const useAppContext = () => {
  return useContext(AppContext);
};
