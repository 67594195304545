import React from 'react'
import Icon, { IconType } from "../../utils/icons/Icon";
import { formatNumber } from '../../utils/number-formatter';
import { formatLargeNumber } from '../../utils/formate-large-number';



function BalanceCard({ iconName, title, amount }: { iconName: IconType, title: string, amount: number }) {
    const formattedValue = formatNumber(amount);
    const formattedAmount = formatLargeNumber(amount);
    return (
        <div className='space-y-5 px-2 md:px-12 flex flex-col md:block items-center'>
            <Icon icon={iconName} className='text-5xl' />
            <p className='text-md md:text-lg'>{title}</p>
            <h3 className='font-semibold text-2xl md:text-3xl lg:4xl'>${formattedAmount}</h3>
        </div>
    )
}

export default BalanceCard
